import reportWebVitals from "./reportWebVitals";

import("./AppBootstrap").then(async ({ renderApp }) => {
  await renderApp();
});

reportWebVitals();

export { };

